import { Routes } from '@angular/router';
import { authorizationGuard, canMatchGuard } from './core/guards/authorization.guard';
import { nonAuthorizeGuard } from './core/guards/non-authorize.guard';

export const routes: Routes = [
  {
    path: 'login',
    canActivate: [nonAuthorizeGuard],
    loadComponent: () => import('./features/login/login.page.component').then((c) => c.LoginPageComponent)
  },
  {
    path: 'admin',
    canMatch: [canMatchGuard], // Recommend for lazyloading module by fire once (for initial navigation) (https://blog.jscrambler.com/how-to-auth-lazily-loaded-routes-in-angular)
    canActivate: [authorizationGuard], // It is a tips to fix the multiple check after the initial navigation
    loadChildren: () =>
      import('./features/admin/admin.routes').then((m) => m.routes),
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];
