/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanMatchFn,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

export const authorizationGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const authService = inject(AuthenticationService);
  return authService.isAuthenticated$().pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true;
      }
      authService.clearSessionWithoutEmit();
      return false;
    })
  );
};

export const canMatchGuard: CanMatchFn = (
  _route: Route,
  _segments: UrlSegment[]
):
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree> => {
  const authService = inject(AuthenticationService);
  return authService.isAuthenticated$().pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true;
      }
      authService.clearSessionWithoutEmit();
      return false;
    })
  );
};
