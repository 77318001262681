import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { ReplaySubject, tap } from 'rxjs';
import { UserPermission } from '../constants/user-permission.enum';
import { AuthRequest } from '../models/api/authRequest';
import { UserBoResetPasswordForm } from '../models/api/userBoResetPasswordForm';
import { AuthenticationApiService } from './api/authentication.api.service';

const TOKEN_ID = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private tokenSubject = new ReplaySubject<string | undefined>(1);
  private authenticatedSubject = new ReplaySubject<boolean>(1);
  private userSubject = new ReplaySubject<Record<string, unknown> | null>(1);

  constructor(
    private router: Router,
    private authenticationApiService: AuthenticationApiService
  ) {}

  private emitToken() {
    this.tokenSubject.next(this._getToken());
    this.authenticatedSubject.next(!!this._getToken());
    this.userSubject.next(this._getUserData());
  }

  private _getToken() {
    const token = localStorage.getItem(TOKEN_ID);
    return token ? JSON.parse(token) : null;
  }

  private _getUserData() {
    const token = this.getAccessToken();
    return token ? (jwt_decode(token) as Record<string, unknown>) : null;
  }

  initSession() {
    this.emitToken();
  }

  clearSessionWithoutEmit() {
    localStorage.removeItem(TOKEN_ID);
    this.router.navigate(['/login']);
  }

  clearSession() {
    localStorage.removeItem(TOKEN_ID);
    this.emitToken();
    this.router.navigate(['/']);
  }

  login(payload: AuthRequest) {
    return this.authenticationApiService.login(payload).pipe(
      tap((token) => {
        if (!token.isTmpPassword) {
          localStorage.setItem(TOKEN_ID, JSON.stringify(token));
          this.emitToken();
        }
      })
    );
  }

  resetPassword(payload: UserBoResetPasswordForm) {
    return this.authenticationApiService.login(payload).pipe(
      tap((token) => {
        if (!token.isTmpPassword) {
          localStorage.setItem(TOKEN_ID, JSON.stringify(token));
          this.emitToken();
        }
      })
    );
  }

  logout() {
    return this.authenticationApiService.logout().pipe(
      tap(() => {
        this.clearSession();
      })
    );
  }

  getAccessToken() {
    return this._getToken()?.accessToken;
  }

  getExpiredAt() {
    return this._getToken()?.expiredAt;
  }

  userData$() {
    return this.userSubject.asObservable();
  }

  isLoggedUser(userId: string) {
    const userData = this._getUserData();
    return userData && userData['sub'] === userId;
  }

  loggedUserId() {
    const userData = this._getUserData();
    return userData && userData['sub'];
  }

  isAuthenticated$() {
    return this.authenticatedSubject.asObservable();
  }

  introspectToken(token: string) {
    return this.authenticationApiService.introspection(token);
  }

  getUserProfile(): string | null {
    const userData = this._getUserData();
    return userData && (userData['profile'] as string);
  }

  getUserPermissions(): UserPermission[] {
    const userData = this._getUserData();
    return userData ? (userData['permissions'] as UserPermission[]) : [];
  }

  isAdmin() {
    return 'ADMIN' === this.getUserProfile();
  }
}
